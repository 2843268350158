@use "sass:color" as *
@import src/styles/colors

.warehouseSelector
    background: adjust($companyColor, $lightness: 70%)!important
    color: adjust($lightColor, $lightness: -80%)!important
    width: 9rem!important

.warehouseSelectorLocked
    background: adjust(red, $lightness: 80%)!important
