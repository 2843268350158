@use "sass:color" as *
@import src/styles/colors

.appcontainer
    display: flex
    flex-direction: column
    width: 100%!important
    height: 100%!important
    padding-right: 0!important
    padding-left: 0!important
    margin-right: 0!important
    margin-left: 0!important
    background-color: adjust($companyColor, $lightness: 30%)!important

.appcontent
    flex: 1
    // background: white

.row
    padding-top: 56px // El height del navbar
    height: 100%!important
    width: 100%!important

.appfooter
    flex: none
    background-color: grey

.appfooterNotOnline
    flex: none
    background-color: red
