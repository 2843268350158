@import src/styles/colors

.topbar
    margin-top: -56px
    top: 56px
    background: $companyColor!important
    border-bottom: 0.15rem solid red
    padding-left: 0!important
    padding-top: 0!important
    padding-bottom: 0!important

.logo
    width: 6rem
    display: flex
    align-items: center
    // justify-content: center
    margin-right: 1rem

    img
        width: 100%

.toolbar
    width: 100%!important
    display: flex
    justify-content: flex-end
    align-items: center

.logoutButton
    color: $lightColor!important
    font-size: 2rem
    transition: all 0.2s ease

    &:hover
        color: white!important

.collapse
    margin: 1rem
    font-size: 1.6rem
    transition: all 0.2s ease

    &:hover
        color: white!important
