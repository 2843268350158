@import styles/colors.sass

html, body
    height: 100%
    background: white
    color: #353737
    font-family: "Source Sans Pro", sans-serif
    font-weight: 400
    overscroll-behavior: none
    padding: 0
    margin: 0

#root
    height: 100vh!important

.pointer
    cursor: pointer

.btn-seralven
    color: #fff!important
    background-color: $seralven!important
    border-color: $seralven!important

.btn-action
    color: $seralven!important
    background-color: #eaff00!important
    border-color: #e40b0b!important

.custom-form-control:focus
    border-color: rgb(252, 181, 0)
    background-color: #fcfc8b!important
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.075) inset, 0px 0px 8px rgba(252, 202, 0, 0.5)

.scrollable-list
    height: 95%
    overflow: auto

.fas.disabled,.fa.disabled
    opacity: 0.5
    cursor: not-allowed
    pointer-events: none
