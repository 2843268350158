@use "sass:color" as *
@import src/styles/colors

.leftbar
    height: 100%!important
    max-width: 12rem
    background: adjust($companyColor, $lightness: 20%)!important
    //padding-left: 2rem!important
    //padding-top: 0.5rem!important
    padding-right: 0!important
    list-style-type: none

    li
        background-color: adjust($companyColor, $lightness: 30%)!important
        margin-bottom: 1px

        a
            color: white//adjust($companyColor, $lightness: 70%)!important
            display: flex
            align-items: center
            // justify-content: space-between
            padding: 0.3rem 0.3rem
            transition: all 0.2s ease
            text-decoration: none

            &.active
                background-color: $companyColor!important
                font-weight: bold
                border-bottom: 1px solid red

                &:hover
                    color: red
                    background-color: $companyColor!important

            &:hover
                color: $lightColor!important
                background-color: adjust($companyColor, $lightness: 10%)!important
.leftIcon
    margin-right: 1rem
