.awaitingOverlay  // La parte exterior
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: rgba(0,0,0,0.50)
    animation: fadein 1s
    z-index: 1
    display: flex
    justify-content: center
    align-items: center

.awaitingContent
    width: 100%
    height: 100%
    background-color: transparent!important

@keyframes fadein
    from
        opacity: 0
    to
        opacity: 1
