.gridCard
    cursor: pointer
    &:hover
        background-color: #fff1a1

.disabledCard
    color: white
    background-color: grey
    &:hover
        color: #444647
        background-color: #f56d55
